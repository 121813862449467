<template>
  <div id="group-management" class="col-12">
    <v-row no-gutters>
      <v-col cols="12" class="main-content">
        <v-row class="page-title" no-gutters
          ><span>Create User Groups</span>
        </v-row>
        <v-row class="action-form" no-gutters>
          <v-col cols="12">
            <v-form
              ref="form"
              v-model="valid"
              @submit.prevent="submit"
              autocomplete="off"
              id="group-form"
            >
              <v-row no-gutters class="fill-height">
                <v-col cols="5" class="group-name">
                  <div class="border fill-height">
                    <v-row no-gutters>
                      <p class="label">User Group Name</p>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-text-field
                          v-model="groupName"
                          placeholder="New Group Name"
                          required
                          autocomplete="off"
                          :rules="groupNameRules"
                          class="input-border"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="5" class="menu">
                  <div class="border fill-height">
                    <v-row no-gutters>
                      <p class="label mb-0">Assessible Menus</p>
                    </v-row>
                    <v-row no-gutters class="pt-3">
                      <v-col
                        cols="12"
                        md="4"
                        :key="index"
                        v-for="(value, name, index) in accessibleMenus"
                      >
                      <input
                        v-model="selectedMenu"
                        class="cursor-pointer"
                        style="width: 16px; height: 16px"
                        :name="name"
                        :id="name"
                        :value="name"
                        type="checkbox"
                      />
                      <label class="cursor-pointer ml-2 font-size-18" :for="name">{{ value }}</label>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="2" class="permission">
                  <div class="">
                    <v-row
                      no-gutters
                      class="justify-content-start padding-add-group"
                    >
                      <button class="submit-button">Add Group</button>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row class="table-view" no-gutters>
          <v-col cols="12">
            <v-row class="page-title" no-gutters>
              <span class="label">View User Groups</span>
            </v-row>
            <v-row no-gutters>
              <v-data-table
                :headers="headers"
                :items="groups"
                :items-per-page="pageSize"
                :search="search"
                @pagination="updatePagination"
                no-data-text="No Data"
                :footer-props="footer"
              >
                <template v-slot:top>
                  <v-row no-gutters>
                    <v-col cols="12" id="search-bar">
                      <v-text-field
                        v-model="search"
                        placeholder="Search User Groups"
                        class="search-input"
                        append-icon="fas fa-search"
                        rounded
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-spacer />
                  </v-row>
                </template>
                <template v-slot:[`item.no`]="{ index }">
                  {{ index + 1 + (currentPage - 1) * pageSize }}
                </template>
                <template v-slot:[`item.accessible_menus`]="{ item }">
                  <v-row no-gutters class="icon-flex-start">
                    <div
                      class="menu-icon"
                      v-if="item.accessible_menus.dashboard"
                    >
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_home.svg"
                        transition="scale-transition"
                        width="34"
                        height="34"
                      />
                    </div>
                    <div
                      class="menu-icon"
                      v-if="item.accessible_menus.schedule"
                    >
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_schedule.svg"
                        transition="scale-transition"
                        v-if="item.accessible_menus.schedule"
                        width="34"
                        height="34"
                      />
                    </div>
                    <div class="menu-icon" v-if="item.accessible_menus.user">
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_user.svg"
                        transition="scale-transition"
                        v-if="item.accessible_menus.user"
                        width="34"
                        height="34"
                      />
                    </div>
                    <div class="menu-icon" v-if="item.accessible_menus.setting">
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/settings.svg"
                        transition="scale-transition"
                        v-if="item.accessible_menus.setting"
                        width="34"
                        height="34"
                      />
                    </div>
                    <div class="menu-icon" v-if="item.accessible_menus.log">
                      <v-img
                        alt="name"
                        class="shrink"
                        contain
                        src="../../assets/menu/menu_icon_logs.svg"
                        transition="scale-transition"
                        v-if="item.accessible_menus.log"
                        width="34"
                        height="34"
                      />
                    </div>
                  </v-row>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-row no-gutters>
                    <v-btn :ripple="false" icon>
                      <v-img
                        alt="Users"
                        class="shrink mr-2"
                        contain
                        src="../../assets/tables/Button_edit.svg"
                        transition="scale-transition"
                        @click="openDialog(item)"
                      />
                    </v-btn>
                    <v-btn :ripple="false" icon class="pl-4">
                      <v-img
                        alt="Users"
                        class="shrink mr-2"
                        contain
                        src="../../assets/tables/Button_delete.svg"
                        transition="scale-transition"
                        @click="openConfirmDialog(item)"
                      />
                    </v-btn>
                  </v-row>
                </template>
              </v-data-table>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="1" lg="0" md="0" sm="0" class="">&nbsp;</v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1000"
      content-class="group-dialog"
    >
      <v-card>
        <v-card-title>Edit User Group</v-card-title>
        <v-card-text>
          <v-form
            ref="formDialog"
            v-model="valid"
            @submit.prevent="submitEdit"
            autocomplete="off"
          >
            <v-row no-gutters class="pt-2">
              <v-col cols="12" md="6" id="dialog-left-col">
                <v-card class="pa-2" outlined tile>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12" md="12">
                        <p class="label">User Group Name</p>
                        <v-text-field
                          v-model="editGroupName"
                          placeholder="New User Group Name"
                          required
                          autocomplete="off"
                          :rules="groupNameRules"
                        >
                          ></v-text-field
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card class="pa-2" outlined tile id="dialog-mid-col">
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12" md="12">
                        <p class="label mb-0">Assessible Menus</p>
                        <v-row no-gutters class="pt-4">
                          <v-col
                            cols="12"
                            md="6"
                            :key="index"
                            v-for="(value, name, index) in accessibleMenus"
                          >
                            <input
                              v-model="editSelectedMenu"
                              class="cursor-pointer"
                              style="width: 16px; height: 16px"
                              :name="'edit_' + name"
                              :id="'edit_' + name"
                              :value="name"
                              type="checkbox"
                            />
                            <label class="cursor-pointer ml-2 font-size-18" :for="'edit_' + name">{{ value }}</label>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-col cols="12" class="mt-3 pt-2">
                <v-row no-gutters class="text-right" style="justify-content: end;">
                  <button class="btn btn-secondary btn-custom" type="button" :ripple="false"  @click="closeDialog">Cancel</button>
                  <button class="ml-4 btn btn-primary btn-custom" type="button" :ripple="false" @click="revertEdit">Revert</button>
                  <button class="ml-4 btn btn-primary btn-custom" type="submit" :disabled="!valid" :ripple="false">Save</button>
                </v-row>
              </v-col>
            </v-row>
          </v-form></v-card-text
        >
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="700px"
      content-class="group-dialog"
    >
      <v-card id="group-confirm-dialog">
        <v-card-title>Confirm</v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12 pt-4">
              <span
                >Are you sure you want to delete group
                {{ groupInfo ? groupInfo.name : "" }}?</span
              >
            </v-col>
            <v-col cols="12 pt-4" class="text-right">
              <button class="btn btn-secondary btn-custom" type="button" :ripple="false"  @click="closeConfirmDialog">CANCEL</button>
              <button class="ml-4 btn btn-primary btn-custom" type="button" :ripple="false" @click="deleteGroup(groupInfo)">YES</button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="messageDialog"
      max-width="700"
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm">
        <v-card-title>{{ apiTitle }}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12 pt-4">
              <div v-html="apiMessage"></div>
            </v-col>
            <v-col cols="12 pt-4" class="text-right">
              <button class="ml-4 btn btn-primary btn-custom" type="button" :ripple="false" @click="closeMessage">OK</button>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss" src="../../assets/css/group-management.scss"></style>
<script>
import { accessibleMenus, footerProps } from "../../constants";
import { groupService } from "../../services";
export default {
  data() {
    return {
      accessibleMenus,
      groupName: "",
      selectedMenu: [],
      editGroupName: "",
      editSelectedMenu: [],
      editGroupId: null,
      groupInfo: null,
      search: "",
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      headers: [
        { text: "No", value: "no", sortable: false, filterable: false },
        {
          text: "User Group",
          align: "start",
          value: "name",
        },
        {
          text: "Menu Access",
          align: "start",
          value: "accessible_menus",
          sortable: false,
          "min-width": 300,
          "max-width": 400,
          class:"menu-access"
        },
        {
          text: "Date Created",
          align: "start",
          value: "created_at",
          filterable: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filterable: false,
        },
      ],
      groups: [],
      currentPage: 1,
      pageSize: 4,
      groupNameRules: [
        (v) =>
          !!v ||
          "Input filled is required before you can create new user group",
      ],
      dialog: false,
      valid: false,
      footer: 0,
      total: 0,
      confirmDialog: false,
    };
  },
  mounted() {
    this.getDimensions();
    window.addEventListener("resize", this.getDimensions);
  },
  created() {
    this.footer = Object.assign({}, this.footer, footerProps);
    this.getGroupList();
  },
  methods: {
    getDimensions() {
      if (window.innerWidth >= 3440 && window.innerHeight >= 1440) {
        this.pageSize = 6;
      } else if (window.innerHeight >= 1080) {
        this.pageSize = 4;
      }
    },
    getGroupList() {
      groupService
        .getGroupList()
        .then((res) => {
          if (res?.data?.data?.length) {
            const data = res.data.data;
            this.total = Math.ceil((res.data.data.length || 0) / this.pageSize);
            this.groups = [...data];
          }
        })
        .catch();
    },
    updatePagination(pagination) {
      this.currentPage = pagination.page;
      this.pageSize = pagination.itemsPerPage;
      this.$set(
        this.footer,
        "pageText",
        `${this.currentPage} of ${this.total}`
      );
    },
    revert() {
      this.$refs.form.reset();
    },
    revertEdit() {
      this.setGroupInfo(this.groupInfo);
    },
    submit() {
      if (this.$refs.form.validate()) {
        const data = this.prepareData(
          this.groupName,
          this.selectedMenu
        );
        this.revert();
        groupService
          .storeUserGroup(data)
          .then((res) => {
            if (res.status !== 200) throw (res);
              this.getGroupList();
              this.apiMessage = "Group has been successfully created";
              this.apiTitle = "Success";
              this.messageDialog = true;
              this.selectedMenu = [];
          })
          .catch((err) => {
            let message = "Group cannot be created";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
              this.apiMessage = message;
              this.apiTitle = "Error";
              this.messageDialog = true;
          });
      }
    },
    submitEdit() {
      const data = this.prepareData(
        this.editGroupName,
        this.editSelectedMenu
      );
      const id = this.groupInfo.id;
      groupService
        .updateUserGroup(id, data)
        .then((res) => {
          if (res.status !== 200) throw (res);
          this.closeDialog();
          this.getGroupList();
          this.apiMessage = "Group's information has been successfully updated";
          this.apiTitle = "Success";
          this.messageDialog = true;
          this.selectedMenu = [];
        })
        .catch((err) => {
          let message = "Group's information cannot be updated";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        });
    },
    prepareData(groupName, selectedMenu) {
      const data = {
        name: groupName,
        accessible_menus: {
          dashboard: false,
          schedule: false,
          user: false,
          setting: false,
          log: false
        }
      };
      selectedMenu.map((value) => (data.accessible_menus[value] = true));
      return data;
    },
    openConfirmDialog(item) {
      this.groupInfo = Object.assign({}, this.groupInfo, item);
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.groupInfo = null;
      this.confirmDialog = false;
    },
    deleteGroup(item) {
      groupService
        .deleteUserGroup(item.id)
        .then((res) => {
          if (res.status !== 200) throw (res);
          this.getGroupList();
          this.apiMessage = "Group has been deleted";
          this.apiTitle = "Success";
          this.messageDialog = true;
        })
        .catch((err) => {
          let message = "Group cannot be deleted";
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        })
        .finally(() => {
          this.closeConfirmDialog();
        });
    },
    getGroupInfo(id) {
      return groupService
        .getUserGroup(id)
        .then((res) => {
          if (res.status == 200 && res.data?.data) {
            const data = res.data.data;
            this.groupInfo = Object.assign({}, this.groupInfo, data);
            this.setGroupInfo(this.groupInfo);
          }
        })
        .catch(() => null);
    },
    setGroupInfo(data) {
      if (data) {
        this.editGroupName = data.name;
        if (data.accessible_menus) {
          const selected = Object.keys(data.accessible_menus).reduce(
            (acc, cur) => {
              if (data.accessible_menus[cur]) {
                acc.push(cur);
              }
              return acc;
            },
            []
          );
          this.editSelectedMenu = [...selected];
        }
      } else {
        this.editGroupName = "";
        this.editSelectedMenu = [];
        this.groupInfo = null;
      }
    },
    openDialog(item) {
      this.setGroupInfo(null);
      this.getGroupInfo(item.id).then(() => {
        this.dialog = true;
      });
    },
    closeDialog() {
      this.setGroupInfo(null);
      this.dialog = false;
    },
    closeMessage() {
      this.messageDialog = false;
    },
  },
};
</script>
